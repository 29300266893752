import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardText,
  Alert,
  Container,
  Spinner,
} from "reactstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import Link from "next/link";
import WaitlistModal from "./WaitlistModal";
import moment from "moment-timezone";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";

ProjectInviteDetail.propTypes = {
  projectInvite: shape({
    project: shape({
      id: number.isRequired,
      projectName: string.isRequired,
      description: string,
      address: string,
      pocName: string,
      pocPhone: string,
      siteRequirements: arrayOf(
        shape({
          id: string.isRequired,
          name: string.isRequired,
        })
      ),
      badgingRequired: bool,
      toolsNeeded: string,
      productLines: arrayOf(
        shape({
          id: number.isRequired,
          manufacturer: string.isRequired,
          manufacturerId: number.isRequired,
        })
      ),
    }),
    shiftInvites: arrayOf(
      shape({
        id: string.isRequired,
        status: string.isRequired,
        shiftRate: string.isRequired,
        projectWorker: shape({
          id: string.isRequired,
          name: string.isRequired,
          toolsNeeded: string,
        }),
        projectDate: shape({
          startsAt: string.isRequired,
          endsAt: string.isRequired,
          timezone: string.isRequired,
        }),
      })
    ),
    waitlistedShiftInvites: arrayOf(
      shape({
        shiftRate: string,
      })
    ),
  }).isRequired,
  submitResponse: func.isRequired,
  submitting: bool.isRequired,
};

function ProjectInviteDetail({ projectInvite, submitResponse, updateStatus, submitting }) {
  const SHIFT_STARTED_MESSAGE = "Shift in progress or past";
  const [actionInProgress, setActionInProgress] = useState(null);
  const [modalVisible, setModalVisible] = useState(false)

  const getEstTimeUntilShift = (projectDate) => {
    const diff = moment.duration(
      moment(new Date(projectDate.startsAt)).diff(moment())
    );
    if (diff < 0) return SHIFT_STARTED_MESSAGE;
    return diff.asHours() < 48
      ? `in ${Math.floor(diff.asHours())} hours`
      : `in ${Math.floor(diff.asDays())} days`;
  };

  const getShiftDuration = (startDate, endDate) =>
    moment.duration(endDate.diff(startDate)).asHours();

  const projectDatesForDisplay = projectInvite.shiftInvites.map((si) => {
    const rate = si.shiftRate;
    const pd = si.projectDate;
    const startDate = moment(new Date(pd.startsAt)).tz(pd.timezone);
    const endDate = moment(new Date(pd.endsAt)).tz(pd.timezone);
    const estTimeUntilShift = getEstTimeUntilShift(pd);
    const shiftDuration = getShiftDuration(startDate, endDate);
    return {
      startsAt: startDate.format("MMM Do YYYY, h:mm A z"),
      timeUntilShift: estTimeUntilShift,
      shiftDuration,
      shiftStarted: shiftDuration === SHIFT_STARTED_MESSAGE,
      rate,
    };
  });

  const totalForAllShifts = projectDatesForDisplay.reduce((acc, item) => {
    const rateInDollar = parseFloat(item.rate.replace("$", ""));
    return item.shiftDuration < 0
      ? acc
      : acc + item.shiftDuration * rateInDollar;
  }, 0);

  const [showWaitlistedMessage, setShowWaitlistedMessage] = useState(false);

  const project = projectInvite.project;
  const shiftInvite =
    projectInvite.shiftInvites[0] || projectInvite.waitlistedShiftInvites[0];

  if (!shiftInvite || !shiftInvite.projectWorker) {
    return (
      <Container className="mt-1">
        <Alert color="danger" className="text-center">
          This invite is no longer available.
        </Alert>
        <Button
          href="/dashboard"
          className="mt-1 btn-full-width"
          block
          style={{ backgroundColor: "#f36c21", color: "white" }}
        >
          Go to Dashboard
        </Button>
      </Container>
    );
  }

  useEffect(() => {
    setShowWaitlistedMessage(shiftInvite.status === "waitlisted");
  }, [shiftInvite.status]);

  const getAlertColor = () => {
    switch (shiftInvite.status) {
      case "accepted":
        return "success";
      case "rejected":
        return "warning";
      case "waitlisted":
        return "danger";
      default:
        return "warning";
    }
  };

  const getAlertText = () => {
    switch (shiftInvite.status) {
      case "accepted":
        return "Your spot on this project has been confirmed! Contact the POC when you arrive at the project or if you are running late.";
      case "rejected":
        return "This project has been declined.";
      case "waitlisted":
        return "You have been added to the waitlist for this project.";
      default:
        return "This project is pending acceptance. Please review and select your response at the bottom";
    }
  };

  useEffect(() => {
    // Check that submission has finished, an action was taken,
    // and shiftInvite is updated to a non-pending status.
    if (!submitting && actionInProgress) {
      window.location.reload();
    }
  }, [submitting, actionInProgress]);


  const handleResponse = (status) => {
    setActionInProgress(status);
    submitResponse({ status });
  };

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "10px",
  };

  const [coordinates, setCoordinates] = useState(null);
  const [mapLoading, setMapLoading] = useState(true);
  const [mapError, setMapError] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const defaultCoords = { lat: 39.8283, lng: -98.5795 };

  useEffect(() => {
    if (!project.address) {
      setCoordinates(defaultCoords);
      setMapLoading(false);
      return;
    }

    if (
      typeof window !== "undefined" &&
      window.google &&
      window.google.maps
    ) {
      try {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ address: project.address }, (results, status) => {
          if (status === "OK" && results?.[0]?.geometry?.location) {
            const location = results[0].geometry.location;
            setCoordinates({
              lat: location.lat(),
              lng: location.lng(),
            });
          } else {
            console.error("Geocoding failed:", status);
            setMapError("Location could not be found");
            setCoordinates(defaultCoords);
          }
          setMapLoading(false);
        });
      } catch (error) {
        console.error("Google Maps error:", error);
        setMapError("Failed to access mapping service");
        setCoordinates(defaultCoords);
        setMapLoading(false);
      }
    } else {
      setMapError("Map service not available");
      setMapLoading(false);
    }
  }, [project.address]);

  const handleMarkerClick = useCallback(() => {
    setSelectedMarker(coordinates);
  }, [coordinates]);

  return updateStatus === "failed" ? (<div>{`There was an error updating the project invite. Please reload the page and try again`}</div>):(
    <>
      {/* Banner indicating shift status */}
      <Alert color={getAlertColor()} className="text-center mt-0 mb-2">
        {getAlertText()}
      </Alert>

      {showWaitlistedMessage && (
        <div>
          <strong className="text-danger">
            All spots on this project have been filled and you have been added
            to the waitlist.
            <br />
            <br />
            If anyone cancels or another spot opens up, you will be among the
            first to receive a new invite!
          </strong>
          <br />
          <br />
          <p>
            Visit your{" "}
            <Link href="/dashboard">
              <span className="text-primary text-link">dashboard</span>
            </Link>{" "}
            to see all your scheduled shifts.
          </p>
        </div>
      )}

      <Container className="mt-1">
        <Card>
          <CardBody>
            {/* Project Name and Business Name */}
            <Row className="mb-2 text-center">
              <Col>
                <h1 style={{ marginBottom: "0.5rem" }}>
                  {project.projectName}
                </h1>
                <h4 style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                  {project.businessName}
                </h4>
                <hr className="dashed" />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs="12" md="6">
                <h5 className="mt-0 mb-1">Location</h5>
                <CardText className="mb-1" style={{ color: 'black' }}>
                  <FaMapMarkerAlt /> <b>{project.address}</b>  {project.unit && <p>Unit: {project.unit}</p>}
                </CardText>
              </Col>
              <Col xs="12" md="6" className="mt-2 mt-md-2 mb-0">
                <h5 className="mt-0 mb-1">Project Date(s) & Time</h5>
                {projectDatesForDisplay.map((projectDate, idx) => (
                  <CardText key={idx} className="mb-1">
                    <b style={{ color: "black" }}>
                      {projectDate.startsAt}
                    </b>
                    <br />
                    <strong>Est. {`${projectDate.shiftDuration} hrs `}</strong>
                    <b className="text-primary">({projectDate.rate}/hr)</b>
                  </CardText>
                ))}
                <strong className="mt-0 mb-2">{`Est total $${totalForAllShifts}`}</strong>
              </Col>
            </Row>

            <div className="d-block d-md-none"><br /></div>

            <Row className="mb-2">
              <Col md="6">
                <h5 className="mt-0 mb-1">Site Information</h5>
                <CardText className="mb-1">
                  {project.badgingRequired
                    ? "Badging is required."
                    : "Badging is not required."}
                  <br />
                  {project.siteRequirements &&
                    project.siteRequirements.map((req) => (
                      <div key={req.id}>
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {req.name}
                        </span>
                      </div>
                    ))}
                </CardText>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs="12" md="6" className="mb-2">
                <h5 className="mt-0 mb-1">Tools Needed</h5>
                <CardText className="mb-1">
                  <strong style={{ color: "#f36c21" }}>
                    <a
                      href="https://www.steadyinstall.com/tools"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      {shiftInvite?.projectWorker?.toolsNeeded ||
                        "Not specified"}
                    </a>
                  </strong>
                </CardText>
              </Col>
              <Col xs="12" md="6">
                <h5 className="mt-0 mb-1">Scope of Work</h5>
                <CardText className="mb-1">
                  {project.description}
                </CardText>
              </Col>
              <div className="d-block d-md-none"><br /></div>
              {shiftInvite.status === "accepted" && (
                <Col md="6">
                  <h5 className="mt-0 mb-1">Point of Contact</h5>
                  <CardText className="mb-1">
                    <strong>Name</strong> - {project.pocName}
                    <br />
                    <strong>Phone Number</strong> -{" "}
                    <a href={`tel:${project.pocPhone}`}>{project.pocPhone}</a>
                  </CardText>
                </Col>
              )}
            </Row>

            <Row className="mb-2">
              <Col xs="12">
                <h5 className="mt-0 mb-1">Product Lines</h5>
              </Col>
              {project.productLines && project.productLines.length > 0
                ? project.productLines.map((line) => (
                  <div key={line.id}>
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {line.manufacturer}
                    </span>
                  </div>
                ))
                : null}
              <CardBody>
              </CardBody>
            </Row>

            {shiftInvite.status === "pending" && (
              <Row>
                {/* Accept Button */}
                <Col xs="12">
                  <Button
                    color="success"
                    onClick={() => handleResponse("accepted")}
                    disabled={submitting || actionInProgress === "rejected"}
                    block
                    size="lg"
                    className="w-100 my-2"
                    style={{ color: "white" }}
                  >
                    {actionInProgress === "accepted" && submitting ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Accept Shift"
                    )}
                  </Button>
                </Col>
                {/* Decline Button */}
                <Col xs="12">
                  <Button
                    color="danger"
                    onClick={() => handleResponse("rejected")}
                    disabled={submitting || actionInProgress === "accepted"}
                    block
                    size="lg"
                    className="w-100 my-2"
                  >
                    {actionInProgress === "rejected" && submitting ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Decline Shift(s)"
                    )}
                  </Button>
                </Col>
              </Row>
            )}

            {shiftInvite.status === "accepted" && (
              <Button
                href="/dashboard"
                className="mt-1 btn-full-width"
                block
                style={{ backgroundColor: "#f36c21", color: "white" }}
              >
                Go to Dashboard
              </Button>
            )}
          </CardBody>
        </Card>

        {showWaitlistedMessage && (
          <div className="mt-1">
            <strong className="text-waitlisted">
              All spots on this project have been filled and you have been
              added to the waitlist.
              <br />
              <br />
              If anyone cancels or another spot opens up, you will be among
              the first to receive a new invite!
            </strong>
            <br />
            <br />
            <p>
              Visit your{" "}
              <Link href="/dashboard">
                <span className="text-primary text-link">dashboard</span>
              </Link>{" "}
              to see all your scheduled shifts.
            </p>
          </div>
        )}

        <WaitlistModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          projectDates={[]}
          waitlistedProjectDates={[]}
          submitResponse={submitResponse}
        />

        {/* Google Map Section */}
        <hr className="my-5" />
        <h3 className="mt-0 mb-2">Location</h3>
        <p>{project.address}</p>
        <div className="map-container">
          {mapLoading && <p>Loading map...</p>}
          {mapError && <p style={{ color: "red" }}>{mapError}</p>}
          {!mapLoading && coordinates && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={coordinates}
              zoom={15}
              mapId={process.env.NEXT_PUBLIC_GOOGLE_MAP_ID}
            >
              {coordinates && coordinates.lat && coordinates.lng && (
                <Marker position={coordinates} onClick={handleMarkerClick} />
              )}
              {selectedMarker && (
                <InfoWindow
                  position={coordinates}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <div>{project.address}</div>
                </InfoWindow>
              )}
            </GoogleMap>
          )}
          {!mapLoading && !coordinates && !mapError && (
            <p>No location available.</p>
          )}
        </div>
      </Container >

    </>
  );
}

export default ProjectInviteDetail;