import React from 'react'
import PropTypes from 'prop-types'
import SiteHeaderContainer from '../containers/SiteHeaderContainer'
import SiteFooter from './SiteFooter'

const propTypes = {
  children: PropTypes.node,
  footerStyle: PropTypes.oneOf(['default']),
  headerStyle: PropTypes.oneOf(['default']),
  hideFooter: PropTypes.bool,
  hideHeader: PropTypes.bool,
}

const Layout = ({
  children,
  headerStyle = 'default',
  footerStyle = 'default',
  hideHeader = false,
  hideFooter = false,
}) => (
  <>
    {hideHeader ? <div className="mb-5" /> : <SiteHeaderContainer headerStyle={headerStyle} />}

    <main role="main" className="main">
      {children}
    </main>

    {hideFooter || <SiteFooter footerStyle={footerStyle} />}
  </>
)

Layout.propTypes = propTypes

export default Layout
