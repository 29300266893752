import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';

const LinkActiveNavLink = ({
  children,
  href,
  className = '',
  activeClassName = 'active',
  exact = false,
  ...rest
}) => {
  const router = useRouter();

  // Active link detection
  const isActive = exact
    ? router.pathname === href
    : router.pathname.startsWith(href);

  // Combine class names
  const combinedClassName = `${className} ${isActive ? activeClassName : ''}`.trim();

  return (
    <Link href={href} {...rest} className={combinedClassName}>
      {children}
    </Link>
  );
};

LinkActiveNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool,
};

export default LinkActiveNavLink;
