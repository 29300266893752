import React from 'react';
import { bool, string } from 'prop-types';

const LoadingSM = ({ isSection = false, loadingText = "Loading" }) => (
  <div className={`text-center ${isSection ? 'mb-5' : 'mt-5'} d-flex justify-content-center align-items-center`}>
    <div className="d-block">
      <div className="spinner-border mb-3" role="status" />
      {!isSection && <h1 className="mb-5">{loadingText}</h1>}
    </div>
  </div>
);

LoadingSM.propTypes = {
  isSection: bool,
  loadingText: string,
};

export default LoadingSM;
