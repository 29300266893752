import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { func, string, shape, arrayOf } from "prop-types";
import LoadingSM from "../components/Loading/LoadingSM";
import ProjectInviteDetail from "../components/ProjectInvite";
import {
  retrieveProjectInvite,
  selectProjectInvite,
  selectRetrieveProjectInviteStatus,
  selectUpdateProjectInviteError,
  selectUpdateProjectInviteStatus,
  updateProjectInvite,
} from "../modules/projectInvites";

const propTypes = {
  retrieveProjectInvite: func.isRequired,
  updateProjectInvite: func.isRequired,
  projectInviteId: string.isRequired,
  projectInvite: shape({
    inviteHashedId: string,
    project: shape({}),
    shiftInvites: arrayOf(shape({})),
  }),
};

const mapStateToProps = (state) => ({
  projectInvite: selectProjectInvite(state),
  retrieveProjectInviteStatus: selectRetrieveProjectInviteStatus(state),
  updateProjectInviteError: selectUpdateProjectInviteError(state),
  updateProjectInviteStatus: selectUpdateProjectInviteStatus(state)
});

const mapDispatchToProps = { retrieveProjectInvite, updateProjectInvite };

function ProjectInviteContainer({
  projectInviteId,
  projectInvite,
  retrieveProjectInvite,
  updateProjectInvite,
  retrieveProjectInviteStatus,
  updateProjectInviteError,
  updateProjectInviteStatus
}) {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (projectInviteId) {
      setLoading(true);
      retrieveProjectInvite(projectInviteId);
    }
  }, [retrieveProjectInvite, projectInviteId]);

  useEffect(() => {
    switch (retrieveProjectInviteStatus) {
      case "success":
      case "failed":
        {
          setLoading(false);
        }
      break;
      default:
        return;
    }
  },[retrieveProjectInviteStatus])

  const submitResponse = useCallback(
    async function onSubmitResponse(values) {
      setSubmitting(true);
      updateProjectInvite({
        projectInvite: { status: values ? values.status : false },
        projectInviteId: projectInvite ? projectInvite.inviteHashedId : false,
      });
    },
    [projectInvite, updateProjectInvite, setSubmitting]
  );

  useEffect(() => {
    switch (updateProjectInviteStatus) {
      case "success":
      case "failed":
        {
          setSubmitting(false);
        }
      break;
      default:
        return;
    }

  }, [updateProjectInviteStatus])

  if (loading) return <LoadingSM />;

  if (retrieveProjectInviteStatus === "failed") return "No project invite found.";

  if (projectInvite && projectInvite.status === "expired")
    return "This project invite has expired.";

  return (
    <ProjectInviteDetail
      projectInvite={projectInvite}
      submitResponse={submitResponse}
      updateStatus={updateProjectInviteStatus}
      updateError={updateProjectInviteError}
      submitting={submitting}
    />
  );
}

ProjectInviteContainer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectInviteContainer);
