import React from 'react';
import { Container, Row } from 'reactstrap';
import Layout from '../components/Layout';
import ProjectInviteContainer from "../containers/ProjectInviteContainer";
import { useRouter } from 'next/router';
import Head from 'next/head';

const ProjectInvitePage = () => {
    const router = useRouter();

    if (!router.isReady) {
        return <div>Loading...</div>;
    }

    const { projectInviteId } = router.query;

    if (!projectInviteId) {
        return <div>Error: Project invite ID not found.</div>;
    }

    return (
        <Layout>
            <Head>
                <title>Project Invite | Steady Install</title>
            </Head>
            <Container fluid>
                <Row className="justify-content-center">
                    <ProjectInviteContainer projectInviteId={projectInviteId} />
                </Row>
            </Container>
        </Layout>
    );
};

export default ProjectInvitePage;
