import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isSignedIn } from '../lib/session';
import { connect } from 'react-redux';
import { retrieveUser, selectUser, signOut } from '../modules/users';
import SiteHeader from '../components/SiteHeader/SiteHeader';

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapDispatchToProps = { retrieveUser, signOut };

const SiteHeaderContainer = ({ retrieveUser, signOut, user, headerStyle }) => {
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    let isMounted = true;

    isSignedIn().then((signedInStatus) => {
      if (isMounted) {
        setSignedIn(signedInStatus);
        if (signedInStatus) {
          retrieveUser();
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [retrieveUser]);

  const handleSignOut = async (e) => {
    e.preventDefault();
    await signOut();
  };

  return (
    <SiteHeader
      isSignedIn={signedIn}
      signOut={handleSignOut}
      headerStyle={headerStyle}
      user={user}
    />
  );
};

SiteHeaderContainer.propTypes = {
  retrieveUser: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  user: PropTypes.object,
  headerStyle: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteHeaderContainer);
