import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import React from "react"
import { arrayOf, bool, func } from "prop-types"
import mapProjectDates from "../../lib/mapProjectDates"


WaitlistModal.propTypes = {
  modalVisible: bool.isRequired,
  setModalVisible: func.isRequired,
  projectDates: arrayOf({}).isRequired,
  waitlistedProjectDates: arrayOf({}).isRequired,
  submitResponse: func.isRequired,
}

function WaitlistModal({ modalVisible, setModalVisible, projectDates, waitlistedProjectDates, submitResponse }) {
  const toggleModal = () => {
    setModalVisible(visible => !visible)
  }

  return (
    <Modal isOpen={modalVisible}>
      <ModalHeader className="text-center">
        <h4 className="text-primary">Some Shifts are No Longer Available.</h4>
        <p>Would you like to still accept remaining shifts?</p>
        <hr className="dashed mb-0"/>
      </ModalHeader>
      <ModalBody className="pt-0">
        <h4>Available Project Date(s) & Time</h4>
        {mapProjectDates(projectDates, false)}

        <hr className="dashed mb-3"/>

        <h4 className="text-primary">Waitlisted Dates</h4>
        {mapProjectDates(waitlistedProjectDates, true)}
      </ModalBody>
      <ModalFooter>
        <Button color="info"
                onClick={() => {
                  submitResponse({status: 'waitlisted'})
                  toggleModal()
                }}
                block>Decline Shift(s)</Button>
        <Button color="primary"
                onClick={() => {
                  submitResponse({status: 'accepted'})
                  toggleModal()
                }}
                block>Accept Shift(s)</Button>
      </ModalFooter>
    </Modal>
  )
}

export default WaitlistModal
