import moment from "moment-timezone";
import { Col, Row } from "reactstrap";
import React from "react";

// Maps given project dates in proper display format
export default function mapProjectDates(projectDates, hideData) {
  // Sort project dates and map them to JSX
  return projectDates.sort((firstDate, secondDate) => {
    return new Date(firstDate.startsAt) - new Date(secondDate.startsAt);
  }).map((date) => {
    // Log the date object to ensure data is correct

    return (
      <Row key={date.id || `${date.startsAt}-${date.endsAt}`}>
        <Col xs="12">
          {date.shiftRate ? (
            <div>
              <strong className="text-black">
                {moment(date.startsAt).tz(date.timezone).format('MMM Do YYYY, h:mm A')} {moment.tz(date.timezone).zoneAbbr()}
              </strong>
              <br />
              {!hideData && (
                <>
                  Est. {parseInt(moment.duration(moment(date.endsAt).diff(moment(date.startsAt))).asHours())} hrs
                  <span className="text-primary pl-1">({date.shiftRate}/hr)</span>
                </>
              )}
            </div>
          ) : (
            <div>
              <strong className="text-black">
                {moment(date.startsAt).tz(date.timezone).format('MMMM Do YYYY, h:mm a')} - {moment(date.endsAt).tz(date.timezone).format('h:mm a')} {moment.tz(date.timezone).zoneAbbr()}
              </strong>
              {date.scheduledWorkers.flat().map((worker, workerIndex) => {
                const workerKey = `${date.id}-worker-${workerIndex}`;

                return (
                  <p className="ml-2" key={workerKey}>
                    {worker.name} (Level {worker.level}) - {worker.phoneNumber}
                  </p>
                );
              })}
            </div>
          )}
        </Col>
      </Row>
    );
  });
}
