// components/SiteFooter/SiteFooter.js

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

const propTypes = {
  footerStyle: PropTypes.oneOf(['default']),
};

const SiteFooter = ({ footerStyle = 'default' }) => {
  return (
    <footer className={`footer footer-${footerStyle} mt-5 border-top py-4`}>
      <Container>
        <Row className="align-items-center">
          {/* Left Section */}
          <Col md="4" className="text-center text-md-left mb-3 mb-md-0">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} Steady Install. All Rights Reserved
            </p>
          </Col>

          {/* Center Section */}
          <Col md="4" className="text-center mb-3 mb-md-0">
            <Nav className="justify-content-center">
              <NavItem>
                <NavLink
                  href="https://www.termsfeed.com/privacy-policy/9cf741bb798dc1ca42396351d8637137"
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="navLink"
                >
                  Privacy
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.steadyinstall.com/terms-of-service"
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="navLink"
                >
                  Terms
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact" className="navLink">
                  Contact
                </NavLink>
              </NavItem>
            </Nav>
          </Col>

          {/* Right Section */}
          <Col md="4" className="text-center text-md-right">
            {/* Empty or add social media icons if needed */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

SiteFooter.propTypes = propTypes;

export default SiteFooter;
