import React, { useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, Collapse, NavbarToggler } from 'reactstrap';
import LinkActiveNavLink from '../LinkActiveNavLink';
import PropTypes from 'prop-types';
import Image from 'next/image';

const propTypes = {
  headerStyle: PropTypes.oneOf(['default']),
  isSignedIn: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const BusinessHeaders = ({ ninjaInvoiceKey }) => (
  <>
    <NavItem className="navItem bmItem my-2">
      <LinkActiveNavLink href="/project/new" className="navLink" style={{ whiteSpace: 'nowrap' }}>
        Add Project
      </LinkActiveNavLink>
    </NavItem>
    <NavItem className="navItem bmItem my-2">
      <LinkActiveNavLink href="/dashboard" className="navLink">
        Dashboard
      </LinkActiveNavLink>
    </NavItem>
    <NavItem className="navItem bmItem my-2">
      <LinkActiveNavLink href="/foremen" className="navLink">
        Foremen
      </LinkActiveNavLink>
    </NavItem>
    <NavItem className="navItem bmItem my-2">
      <LinkActiveNavLink href="/dashboard/my-account" className="navLink">
        Account
      </LinkActiveNavLink>
    </NavItem>
    {ninjaInvoiceKey && (
      <NavItem className="navItem bmItem my-2">
        <LinkActiveNavLink target="_blank" href={ninjaInvoiceKey} className="navLink">
          Billing
        </LinkActiveNavLink>
      </NavItem>
    )}
    <NavItem className="navItem bmItem my-2">
      <a href="https://support.steadyinstall.com" className="navLink" target="_blank" rel="noopener noreferrer">
        Support
      </a>
    </NavItem>

  </>
);

BusinessHeaders.propTypes = {
  ninjaInvoiceKey: PropTypes.string,
  signOut: PropTypes.func.isRequired,
};

const WorkerHeaders = () => (
  <>
    <NavItem className="navItem bmItem my-2">
      <LinkActiveNavLink href="/dashboard" className="navLink">
        Dashboard
      </LinkActiveNavLink>
    </NavItem>
    <NavItem className="navItem bmItem my-2">
      <LinkActiveNavLink href="/dashboard/my-account" className="navLink">
        Account
      </LinkActiveNavLink>
    </NavItem>
    <NavItem className="navItem bmItem my-2">
      <LinkActiveNavLink href="/dashboard/worker/payout-info" className="navLink">
        Payment Info
      </LinkActiveNavLink>
    </NavItem>
    <NavItem className="navItem bmItem my-2">
      <a href="https://support.steadyinstall.com" className="navLink" target="_blank" rel="noopener noreferrer">
        Support
      </a>
    </NavItem>
  </>
);

WorkerHeaders.propTypes = {
  signOut: PropTypes.func.isRequired,
};

const SiteHeader = ({ headerStyle = 'default', isSignedIn, signOut, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <header className={`header header-${headerStyle} d-flex align-items-center p-3 pl-lg-4 py-lg-4 mb-5`}>
      <Navbar expand="md" className="p-0 w-100">
        <NavbarBrand
          href={isSignedIn ? '/dashboard' : '/'}
          className="logo p-0 m-0 navbarBrand"
        >
          <Image
            src="/static/images/logo-login.svg"
            alt="Logo"
            width={176}
            height={39}
            className="logoSvg"
          />
        </NavbarBrand>

        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="flex-column flex-md-row p-3 offset-md-2 offset-lg-4 offset-xl-4" navbar>
            {!isSignedIn ? (
              <>
                <NavItem className="navItem bmItem my-2">
                  <LinkActiveNavLink href="/" className="uaTrack-login navLink" exact>
                    Login
                  </LinkActiveNavLink>
                </NavItem>
                <NavItem className="navItem bmItem my-2">
                  <a href="https://support.steadyinstall.com" className="navLink" target="_blank" rel="noopener noreferrer">
                    Support
                  </a>
                </NavItem>
              </>
            ) : user && user.role === 'worker' ? (
              <WorkerHeaders signOut={signOut} />
            ) : (
              <BusinessHeaders
                ninjaInvoiceKey={user?.business?.ninjaInvoiceKey}
                signOut={signOut}
              />
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};

SiteHeader.propTypes = propTypes;

export default SiteHeader;
